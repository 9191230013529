// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Imports -=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
import React from 'react'
import PropTypes from 'prop-types'
// import fucsBanner from './assets/fucs-header.png'

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Change Listeners -=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
// this is where the functions that will run when the user changes the value of a field are defined

function handleSubmit (event) { // this is the function that will run when the form is submitted
  event.preventDefault()
  console.log('form submitted')
  const firstname = event.target.elements.firstname.value
  alert(firstname)
}

function studentStatusChange (value) { // hide or show the FAN and Student ID fields depending on the student/staff status
  console.log(value)
  if (value === 'Not affiliated with Flinders' || value === 'Yes, I\'m an alumni') {
    document.getElementById('ifFlindersStudent').style.display = 'none'
  } else if (value.includes('staff')) { // if the user is staff, hide the student ID field
    document.getElementById('ifFlindersStudent').style.display = 'block'
    document.getElementById('ifFlindersStudent').style.display = 'block'
    document.getElementById('studentId').style.display = 'none'
  } else { // if the user is a student, show all student questions
    document.getElementById('ifFlindersStudent').style.display = 'block'
    document.getElementById('studentId').style.display = 'block'
  }
}

function over18Change (value) { // hide date of birth if over 18
  if (value.includes('Yes')) { // if the user is over 18, hide the date of birth field
    document.getElementById('over18Details').style.display = 'none'
  } else { // if the user is under 18, show the date of birth field
    document.getElementById('over18Details').style.display = 'block'
  }
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Input Validation -=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
InputElementText.propTypes = { // InputElementText is the template for the text input fields
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  pattern: PropTypes.string,
  description: PropTypes.string
}

SelectorElementInput.propTypes = { // SelectorElementInput is the template for the radio button input fields
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  arrayOfOptions: PropTypes.array.isRequired,
  onChangeFunction: PropTypes.func
}

TitleBlock.propTypes = { // TitleBlock is the template for the title blocks
  title: PropTypes.string.isRequired
}

DropdownMenu.propTypes = { // DropdownMenu is the template for the dropdown menu
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  arrayOfOptions: PropTypes.array.isRequired,
  onChangeFunction: PropTypes.func
}

// function checkFAN (string) {
//   const regex = '/^[A-Za-z]{1,4}[0-9]{4}$/'
//   return regex.test(string)
// }

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Question Templates -=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //

function InputElementText ({ id, displayName, type, autoComplete, pattern, description }) { // this is the template for the text input fields
  return (
        <div id={id} className="sm:col-span-3 pb-7">
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                <b>{displayName}</b>
            </label>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                {description}
            </p>
            <div className="mt-2">
                <input
                    type={type}
                    name={id}
                    id={id}
                    autoComplete={autoComplete}
                    pattern={pattern}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            </div>
        </div>
  )
}

function SelectorElementInput ({ id, title, description, arrayOfOptions, onChangeFunction }) { // this is the template for the radio button input fields
  const options = arrayOfOptions.map((option, index) =>
        <div className="flex items-center gap-x-3" key={option}>
            <input
                id={`${id}-${index}`}
                name={id}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                value={option}
                onChange={onChangeFunction ? () => onChangeFunction(option) : null}

            />
            <label htmlFor={`${id}-${index}`} className="block text-sm font-medium leading-6 text-gray-900">
                {option}
            </label>
        </div>
  )
  return (
        <fieldset className="pb-7">
            <legend className="block text-sm font-medium leading-6 text-gray-900"><b>{title}</b></legend>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                {description}
            </p>
            <div className="mt-6 space-y-6">
                {options}
            </div>
        </fieldset>
  )
}

function TitleBlock ({ title = '' }) { // this is the template for the title blocks
  return (
        <>
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start pb-7">
                    <span className="bg-white pr-3 text-xl font-semibold leading-6 text-gray-900">{title}</span>
                </div>
            </div>
        </>
  )
}

function DropdownMenu ({ id, title, description, arrayOfOptions, onChangeFunction }) { // this is the template for the dropdown menu input fields
  const options = arrayOfOptions.map((option, index) =>
        <option value={option} key={option}>{option}</option>
  )
  return (
        <div className="pb-7">
            <legend className="block text-sm font-medium leading-6 text-gray-900"><b>{title}</b></legend>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                {description}
            </p>
            <select
                id={id}
                name={id} className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                {options}
            </select>
        </div>
  )
}

// function CheckBoxs ({ id, title, description, arrayOfOptions, onChangeFunction }) { // this is the template for the dropdown menu input fields

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Form -=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //

function CustomFormData () { // this is the form
  return (
        <>
            <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
            {/* <img src={fucsBanner} alt="FUCSBannerImage" /> */}
            <h1 className="text-5xl font-semibold text-gray-900">Member Signup</h1>
            <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">

                        <TitleBlock
                            title="Personal Information"
                        />

                        {/* First and last names */}
                        <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-6">
                            <InputElementText
                                id="firstname"
                                displayName="First Name (preferred)"
                                type="text"
                                autoComplete="given-name"
                            />

                            <InputElementText
                                id="lastname"
                                displayName="Last Name"
                                type="text"
                                autoComplete="family-name"
                            />
                        </div>

                        <TitleBlock
                            title="Student Details"
                        />

                        <SelectorElementInput
                            id="studentStatus"
                            title="Are you affiliated with Flinders University?"
                            description="We like to keep track of our students"
                            arrayOfOptions={
                                [
                                  "Yes, I'm a current Student",
                                  "Yes, I'm a current staff member",
                                  "Yes, I'm an alumni",
                                  'Not affiliated with Flinders'
                                ]}
                            onChangeFunction={studentStatusChange}
                        />

                        <div id="ifFlindersStudent">

                            <InputElementText
                                id="fan"
                                displayName="FAN (Flinders Authentication Number)"
                                type="text"
                                description="(Okta Username, eg ABCD1234)"
                            />

                            <InputElementText
                                id="studentId"
                                displayName="Flinders Student ID"
                                type="number"
                                pattern="\d{7,7}"
                                description="7 digit number on your student card"
                            />

                            <DropdownMenu
                            id="college"
                            title="College"
                            description="Please select your college"
                            arrayOfOptions=
                            {
                                [
                                  'Science and Engineering',
                                  'Business, Government and Law',
                                  'Education, Psychology and Social Work',
                                  'Humanities, Arts and Social Sciences',
                                  'Medicine and Public Health',
                                  'Nursing and Health Sciences'
                                ]
                            }
                            />

                        <DropdownMenu
                            id="cse-course"
                            title="IT / CS Course"
                            description="If available, select your course"
                            arrayOfOptions=
                            {
                                [
                                  'None of the below',
                                  'Computer Science',
                                  'Computer Science (Artificial Intelligence)',
                                  'Software Engineering',
                                  'Geospatial Information Systems',
                                  'IT',
                                  'IT (Business and Information Systems)',
                                  'IT (Data Analytics)',
                                  'IT (Digital Forensics)',
                                  'IT (Game Development)',
                                  'IT (Machine Learning)',
                                  'Network and Cybersecurity Systems',
                                  'Mathematical Sciences',
                                  'Certificate in Industry 4.0'
                                ]
                            }
                        />

                        </div>

                        <InputElementText
                            id="email"
                            displayName="Preferred email for club contact"
                            type="email"
                            autoComplete="email"
                        />

                        <TitleBlock
                            title="Member Safety"
                        />

                        <SelectorElementInput
                            id="over18"
                            title="Are you over the age of 18?"
                            description="For some of our events we need to be aware of the age of our participants"
                            arrayOfOptions={["Yes, I'm 18 years old or over", "No, I'm a minor"]}
                            onChangeFunction={over18Change}
                        />
                        <div id="over18Details">

                            <InputElementText
                                id="dateofbirth"
                                displayName="Select your birth month and year"
                                description="We'll use this to mark you as an adult after you turn 18"
                                type="date"
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Save
                    </button>
                </div>
            </form>
        </>
  )
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Compile Form -=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=- //
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //

export default CustomFormData
