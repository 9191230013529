import React from 'react'
import ReactDOM from 'react-dom/client'
import './output.css'
// import App from './App';
import CustomFormData from './Form-app'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"> {
      <CustomFormData />
    }
  </div>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
